import React from "react";
import "./SpinLoader.scss";

export default function SpinLoader() {
  return (
    <p className="circle">
      <span className="ouro ouro3">
        <span className="left"><span className="anim"></span></span>
        <span className="right"><span className="anim"></span></span>
      </span>
    </p>
  );
}
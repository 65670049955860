import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SpinLoader from './components/Common/spinLoader/SpinLoader';
import { Suspense } from 'react';


const HeaderNav = React.lazy(()=>import('./components/Layout/Header'));
const FooterDetails = React.lazy(()=>import('./components/Layout/Footer'));

const HomePage = React.lazy(() => import('./pages/Home'));
const Accreditation = React.lazy(() => import('./pages/Accreditation'));
const Careers = React.lazy(() => import('./pages/Careers'));
const WhoWeAre = React.lazy(() => import('./pages/WhoWeAre'));
const OurTeam = React.lazy(() => import('./pages/OurTeam'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Products = React.lazy(() => import('./pages/Products'));
const PageNotFound = React.lazy(() => import('./pages/PageNotFound'));
const JobDescription = React.lazy(() => import('./pages/JobDescription'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const CookiePolicy = React.lazy(() => import('./pages/CookiePolicy'));



function App() {
  return (
    <Suspense fallback={<SpinLoader />}>
    <div className="App">
      {/* <SpinLoader/> */}
      <HeaderNav />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={
          <HomePage />
        } />
        <Route path="/who-we-are" element={
          <WhoWeAre />
        } />
        <Route path="/our-team" element={
          <OurTeam />
        } />
        <Route path="/accreditation" element={
          <Accreditation />
        } />
        <Route path="/careers" element={
          <Careers />
        } />
        <Route path="/contact" element={
          <Contact />
        } />
        <Route path="/products" element={
          <Products />
        } />
        <Route path="/job-description" element={
          <JobDescription />
        } />
        <Route path="/privacy-policy" element={
          <PrivacyPolicy />
        } />
        <Route path="/cookie-policy" element={
          <CookiePolicy />
        } />
        <Route path="*" element={
          <PageNotFound />
        } />
      </Routes>
      <FooterDetails />
    </div>
    </Suspense>
  );
}

export default App;
